module.exports = [{
      plugin: require('/home/deploy/code/spiral-classics/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":300,"wrapperStyle":"margin-left: 0;"},
    },{
      plugin: require('/home/deploy/code/spiral-classics/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/deploy/code/spiral-classics/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"palette":{"primary":{"main":"#385D5D"},"secondary":{"main":"#834174"}},"typography":{"useNextVariants":true,"fontFamily":"Trebuchet MS,Lucida Sans Unicode,Lucida Grande,Lucida Sans,Arial,sans-serif"}}},
    },{
      plugin: require('/home/deploy/code/spiral-classics/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
